export const environment = {
  production: false
};

/************************** *********/
/******** configuración DEV *******/
/******** **************************/

export const ENV = {
  baseApi: 'https://apigw.ucchristus.cl',
  servRoute: '/agendaambulatoria-test',
  profRoute: '/profesionales-test',
  fileRoute: '/files-test',
  validarEnrolamiento: 'https://ecommerceucv2.lfi.cl/ServicioMarcaPaciente/validarenrolamientoV2',
  rutaSubirDocumentoPaciente: 'https://ecommerceucv2.lfi.cl/serviciomarcapaciente/subirdocumentospacientebase64',
  archivosSiteFinity: 'https://ucupgrade143.lfi.cl/docs/default-source/reservamedica',
  urlMarcaWeb: 'https://marcasuc.lfi.cl/api/Marcas',
  idPlanSaludInit: '4c30555e-5ed3-418f-8f54-a91a00ace99b',
  idPlanSaludAdministrada: '018ed73d-6bd2-46e9-8e95-acfc0134435b',
  idCentrosNoDisponibles: [],
  idCentroPrioritario: '52e43c90-8ab7-4e34-afcb-a96f0106bbd1',
  mensajeSinCupos: '<h5>Estimado paciente, actualmente no encontramos citas disponibles en el centro de su elección. Intente nueva búsqueda en todos los centros.</h5>',
  idExamenProcedimiento: 'b2461ac2-9c50-4724-9676-a91a00a91a02',
  idLaboratorioClinico: '86399d7e-7bd3-4bed-b18a-aca100fc732c',
  idAreaLaboratorio: '1a2ec3c8-90f6-4b07-82ba-abbb017226bd',
  apiDerivacionDetalle: {
    username: 'LFIDevIntegraciones',
    password: 'C364B37xj7p34U8HwDeC8WQBkVXxwHB8',
    ambiente: 'portalclinico-dev-pri-usw2',
    ruta: 'indicaciones',
    api: 'https://api.dev.ucchristus.io',
  },
  habilitarMarcasWeb: false,
  habilitarListaEspera: true,
  habilitarListaEsperaProcedimientos: false,
  habilitarMensajeDerivacion: false,
  areaConsultaMedica: {
    id: '2a8202d9-1ebd-4f2e-a359-a91a00a91a02',
    nombre: 'Consultas'
  },
  donacionBancoDeSangre: {
    idArea: '096a0e24-4422-4985-a3d0-ae5501319fe9',
    idEspecialidad: '29777af5-db1b-4b35-abb2-abd50114e6b8',
    idServicio: '5128800d-135a-4d00-8f73-ae55013357c6'
  },
  idRegion: '6bad9b25-d5df-4565-b5fe-a6f701444053',
  saludIntegral: {
    idEspecialidad: '507ef9a7-0d7e-41b8-ae09-a93400e5d5c8',
    idServicio: 'eb29c47f-1774-4e0f-9a5d-ab8e00fc5716',
    mostrarEspecialidadCabeceraExtras: [{
      idEspecialidad: '******',
      idServicio: '*****',
    }, {
      idEspecialidad: '****',
      idServicio: '*****',
    }]
  },

  idOcultarServicios: [
    'd6d84ad5-b78c-4527-8316-ad3900da30ee',
    'eb29c47f-1774-4e0f-9a5d-ab8e00fc5716'],
  idOcultarEspecialidades: [
    '55741061-2813-4bfd-af3b-ad570164f446',
    '740cd374-9d38-4eb4-aec0-ad7501124ce3'
  ],
  ginecologia: {
    nombre: 'GINECOLOGIA Y OBSTETRICIA',
    idEspecialidad: 'b6fb250f-fff4-4f6e-9436-a93400e4d00d',
    idServicio: '8193f6de-8308-420e-9309-a934015b1999'
  },
  oftalmologia: {
    nombre: 'OFTALMOLOGIA',
    idEspecialidad: '274daf84-5ed6-4e8c-bbf0-a93400e91bd4',
    idServicio: 'a163c23a-285a-4e9a-9e37-a934015b223e'
  },
  planesSaludOcultos: ['018ed73d-6bd2-46e9-8e95-acfc0134435b', '7464fdd5-ec20-407a-984a-b03b015ec191'],
  tokenAutenticar: 'T3BMdXo5ckdJaXlvY0FJNg==',
  pathAutenticar: '/auth-test/Autenticar',
  analyticsCode: 'UA-143119471-1',
  activarSaludIntegral: true,
  bloquearAreaRadiologia: false,
  pathUsuarios: '/auth-test',
  cantidadMaximaOrdenes : 4,
  apiCargaOrdenes: "https://upload-agendauc.lfi.cl/api/agendauc/put_file",
  correoExamenesAdjuntos: null,
  urlSitioSantander: 'https://www.ucchristus.cl/seguro-complementario-santander/Home',
  idMensajeSantander: 'e65b4203-d736-4a70-ab93-b037015e129e',
  medicinaCabecerAdultos: {
    PRESENCIAL: { id: '3232704a-6bd1-4101-b495-af9400e83d0d', detalle: 'MEDICINA DE CABECERA', idEspecialidad: '18113004-cfc1-4a7c-8799-ad120102ceaa' },
    TLM: { id: '67914866-21ed-4b9f-837a-af5d0006e10c', detalle: 'MEDICINA DE CABECERA TLM', idEspecialidad: '18113004-cfc1-4a7c-8799-ad120102ceaa' }
  },
  medicinaCabecerMenores: {
    PRESENCIAL: { id: 'a37ac6f8-b032-46e7-8f28-ad7501130f46', detalle: 'MEDICINA DE CABECERA', idEspecialidad: '740cd374-9d38-4eb4-aec0-ad7501124ce3' },
    TLM: { id: '40005643-b304-4dde-93ee-ad7501133198', detalle: 'MEDICINA DE CABECERA TLM', idEspecialidad: '740cd374-9d38-4eb4-aec0-ad7501124ce3' }
  },
  areasConEncuestas: []
}

export const EspecialidadesDerivaciones = [
 {
    idEspecialidad: 'b6fb250f-fff4-4f6e-9436-a93400e4d00d',
    idServicio: '8193f6de-8308-420e-9309-a934015b1999',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Consulta Médica'
  },
  {
    idEspecialidad: '274daf84-5ed6-4e8c-bbf0-a93400e91bd4',
    idServicio: 'a163c23a-285a-4e9a-9e37-a934015b223e',
    nombre: 'OFTALMOLOGIA - Consulta Médica'
  }
]
  

export const TooltipsAreas = {
  "2a8202d9-1ebd-4f2e-a359-a91a00a91a02": {
    nombreArea: "Consultas",
    tooltip: "Consultas Médicas y de otros profesionales presenciales en alguno de nuestros centros médicos.",
    mensajeArea : ``, /** Mensaje de Area */
    mensajeAreaActivo: false
  },
  "9afabce6-fbf7-4dce-9fdf-ab810146597a": {
    nombreArea: "Telemedicina",
    tooltip: "Consultas virtuales médicas y no médicas",
    mensajeArea : ``, /** Mensaje de Area */
    mensajeAreaActivo: false
  },
  "RIS_IMAGENES": {
    nombreArea: "Radiología e imágenes",
    tooltip: "Imágenes radiológicas, resonancias, scanner, TAC-CT, densitometrías, ecografías y doppler (salvo ginecológicas)",
    mensajeArea : `Para exámenes de PET CT, Medicina Nuclear, procedimientos médicos (punciones y biopsias) y exámenes pediátricos
      llamar a call center 6767000 opción 3. Exámenes de Radiografía Simple no requieren agendamiento, dirigirse directamente a cada centro.`,
    mensajeAreaActivo: ENV.bloquearAreaRadiologia === false
  },
  "1a2ec3c8-90f6-4b07-82ba-abbb017226bd": {
    nombreArea: "Exámenes de Laboratorio",
    tooltip: "Procedimientos y exámenes ambulatorios no radiológicos, ecografías ginecológicas",
    mensajeArea : `Para todos los exámenes de sangre y orina (*), puede agendar online o asistir de forma espontánea a cualquiera de nuestras unidades. Si agenda hora, será atendido de forma más rápida, ya que nuestras unidades cuentan con prioridad de llamado en estos casos. Para más información, visite la <a href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras">sección de toma de muestras</a>.
    <br><span class="text-initial">*Exceptuando curva de glucosa e insulina. </span>`, /** Mensaje de Area */
    mensajeAreaActivo: true
  },
  "b2461ac2-9c50-4724-9676-a91a00a91a02": {
    nombreArea: "Procedimientos Clínicos",
    tooltip: "Procedimientos y exámenes ambulatorios no radiológicos, ecografías ginecológicas",
    mensajeArea : ``, /** Mensaje de Area */
    mensajeAreaActivo: false
  },
  "630df842-4e17-475d-a320-a91a00a91a02": {
    nombreArea: "Kinesiología",
    tooltip: "Solo evaluaciones kinesiológicas iniciales, para pacientes en tratamiento llamar al 22 676 7000 Opción 123",
    mensajeArea : `Reservas en Centro Escuela Militar, contactarse al <a href="mailto:contacto.odontologia@ucchristus.cl">contacto.odontologia@ucchristus.cl</a> o al  <a href="tel:+56224820000">+56 2 2482 0000</a>.`, /** Mensaje de Area */
    mensajeAreaActivo: false
  },
  "096a0e24-4422-4985-a3d0-ae5501319fe9": {
    nombreArea: "Banco de Sangre",
    tooltip: "Agendar horas para donación de sangre, tendrá que contestar una encuesta",
    mensajeArea : `La hora debe ser agendada con los datos del donante. Los datos del paciente le serán requeridos al momento de la donación.`, /** Mensaje de Area */
    mensajeAreaActivo: true
  },
  "68a1a121-510f-4a1d-bb78-acc201643c8b": {
    nombreArea: "Vacunatorio",
    tooltip: "Vacunas que requieren agendamiento como Covid e influenza. Programa nacional en Centro médico San Joaquin, resto de centros o vacunas por orden de llegada",
    mensajeArea : ``, /** Mensaje de Area */
    mensajeAreaActivo: false
  }
}


export const ColoresCentros = {

  ////Centro Médico Marcoleta//
  "b9f90284-411c-4f99-84cc-a91a00c9bf31" : "background: #b2d9ed; color:#000;",
  //Clínica San Carlos de Apoquindo //
  "8ca284cf-482a-4248-bc8f-a92800f90207" : "background: #642e62; color:#fff;",
  //Edificio Médico Alcántara//
  "cbbe27f2-659d-4867-ab5b-a92800fa68a9" : "background: #c38fbd; color:#000;",
  //Centro Médico San Joaquín
  "1b054407-7a26-495f-95ed-a92800fb378d" : "background: #a39ed4; color:#000;",
  //Centro Médico Lira 2//
  "b9528fd6-7d19-4b26-aeba-a990011ecef6" : "background: #b2d9ed; color:#000;",
  //Telemedicina//
  "3f4e398f-8651-40de-985f-ab81015cb959" : "background: #7ec977; color:#000;",
  //Banco de Sangre//
  "7ee3b575-c19d-4503-893d-abd50114a294" : "background: #307FE2; color:#000;",
  //Santa Lucía QA//
  "1739d9d6-21b5-47e3-8bd8-aed300e112e4" : "background: #46A5F1; color:#000;",

  //Escuela Militar//
  "4fcb38f0-5eec-4b45-bd23-afa50108992a" : "background: #B65DC7; color:#000;",
  //Providencia//
  "b1f0c6016-90b5-49f5-a7f3-afa50108ca2f" : "background: #FFEE86; color:#000;",
  //UTM Agustinas//
  "a7fd10bc-a06a-408b-ae42-afa5010912d7" : "background: #307FE2; color:#000;",
  //UTM Cantagallo//
  "89bca84a-fbfe-42a1-ad37-afa501094430" : "background: #307FE2; color:#000;",
 //UTM Cerro el Plomo//
  "2069553d-cb26-4335-8f5f-afa501098748" : "background: #307FE2; color:#000;",
 //UTM Guarda Vieja//
  "b98d720b-2fdd-44f1-8bdc-afa50109c374" : "background: #307FE2; color:#000;",
 //UTM Lo Beltrán//
  "b1aeef30-f82a-410c-bc42-afa5010a0108" : "background: #307FE2; color:#000;",
 //UTM Plaza Ñuñoa//
  "86014797-c97b-4b38-9716-afa5010a31bd" : "background: #307FE2; color:#000;",
 //UTM Tobalaba//
  "075bf1b8-c6e1-4abd-80db-afa5010a60ac" : "background: #307FE2; color:#000;",
 //UTM Escuela Militar//
  "f10ba394-267a-4a54-820e-afaf015b2585" : "background: #307FE2; color:#000;",
  //Centro Medico San Jorge//
  "7da334cd-e9ea-487a-9a09-a92800fa160f" : "background: #f2c0eb; color:#000;",
  //Centro del Cáncer
  "800d238f-875a-47a4-878b-abcf011bc2bd" : "background: #7badcc; color:#000;",

  //Centro Medico Irarrázaval
  "08892649-ef45-424f-883e-a974012fb2dc" : "background: #fddd98; color:#000;",
  //Centro Medico Lira 4
  "a7802055-9802-4213-9341-a988015796b4" : "background: #4482ac; color:#fff;",


  //Centro Medico Lira 5
  "76d9cdd0-983b-4cb8-9741-ab4a00e86c45" : "background: #935f90; color:#fff;",
  //Centro Medico Lira 6
  "33e917c6-ac05-4be5-83f1-acfb014a5a6e" : "background: #0d568b; color:#fff;",
  //Club Deportivo Universidad Católica
  "086e7679-b096-4095-99f9-aabd00eb1aec" : "background: #fddd98; color:#000;",
  //Centro Médico Lira Piso 1 0=c
  "086e7679-b096-4095-99f9-aabd00eb1ae0" : "background: #980052; color:#000;",
}

export const OrdenesCentros = [
  "6bad9b25-d5df-4565-b5fe-a6f701444053", /** Región Metropolitana */
  "8CA284CF-482A-4248-BC8F-A92800F90207", /** Clínica San Carlos de Apoquindo */
  "1739D9D6-21B5-47E3-8BD8-AED300E112E4", /** Santa Lucía */
  "cbbe27f2-659d-4867-ab5b-a92800fa68a9", /** Centro Médico Alcántara */
  "7DA334CD-E9EA-487A-9A09-A92800FA160F", /** Centro Médico San Jorge */
  "B9528FD6-7D19-4B26-AEBA-A990011ECEF6", /** Centro Médico Lira 2 */
  "76D9CDD0-983B-4CB8-9741-AB4A00E86C45", /** Centro Médico Lira Piso 5 */
  "33E917C6-AC05-4BE5-83F1-ACFB014A5A6E", /** Lira Piso 6 */
  "1B054407-7A26-495F-95ED-A92800FB378D", /** Centro Médico San Joaquín */
  "4FCB38F0-5EEC-4B45-BD23-AFA50108992A", /** Escuela Militar */
  "1F0C6016-90B5-49F5-A7F3-AFA50108CA2F", /** Providencia */
  "B9F90284-411C-4F99-84CC-A91A00C9BF31" /** Centro Médico Marcoleta */
]

export const LISTADO_PLANES_SALUD_INTEGRAL = [
  { idPlan: '7464fdd5-ec20-407a-984a-b03b015ec191', nombrePlan : 'Plan Santander', isSantander: true },
  { idPlan: '018ed73d-6bd2-46e9-8e95-acfc0134435b', nombrePlan : 'Salud Integral' }
]

export const sid = '8080808080808080';
export const sidtime = 7200000
export const DIAS_MAXIMOS_PROXIMAS_CITAS=30;

export const MensajesEncuestasAreas = {
  "2a8202d9-1ebd-4f2e-a359-a91a00a91a02": {
    nombreArea: "Consultas",
    mensaje: "Sin Info.",
    mensajeActivo: false, 
  },
  "RIS_IMAGENES": {
    nombreArea: "Radiología e imágenes",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "1a2ec3c8-90f6-4b07-82ba-abbb017226bd": {
    nombreArea: "Exámenes de Laboratorio",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "b2461ac2-9c50-4724-9676-a91a00a91a02": {
    nombreArea: "Procedimientos Clínicos",
    mensaje: "Sin Info.",
    mensajeActivo: false

  },
  "630df842-4e17-475d-a320-a91a00a91a02": {
    nombreArea: "Kinesiología",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "096a0e24-4422-4985-a3d0-ae5501319fe9": {
    nombreArea: "Banco de Sangre",
    mensaje: `
      <p class="nota gb">(*) Si usted tiene entre 65 y 70 años y/o su nacionalidad es Argentina, Colombiana,
          Brasileña, China, Peruana, será evaluado en la entrevista personal que se efectúa en el Banco de Sangre.
      </p>
      <p class="nota gb">
          (*) Para más detalles de los países y ciudades contraindicados por favor presione 
          <a href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/banco-de-sangre" target="_blank">AQUÍ</a>.
      </p> `,
    mensajeActivo: true,
    mensajesPorServicio: {
      '5128800d-135a-4d00-8f73-ae55013357c6' : { // Entrevista Presencial Donación de Sangre
        mensaje: `
        <p class="nota gb">Este es un mensaje para el idServicio Entrevista Presencial Donación de Sangre.</p>`,
        mensajeActivo: false
      }
    }
  },
  "68a1a121-510f-4a1d-bb78-acc201643c8b": {
    nombreArea: "Vacunatorio",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "f9af9bb6-9fc9-47fb-be7b-b0cf013d6e57": {
    nombreArea: "PAD FONASA",
    mensaje: "Sin Info.",
    mensajeActivo: false,
    mensajesPorServicio: {
      '2ed19dee-3ddd-4926-ad08-b0cf0144417f' : { //
        mensaje: `
        <p class="nota gb">IMC o Índice de masa corporal se calcula como: el peso en kilos dividido por la altura (estatura) al cuadrado (Kg/m2). Ejemplo: 68 ÷ 1,65<sup>2</sup> (2,7225) = 24,98.</p>`,
        mensajeActivo: true
      },
      'e3c84594-99c8-4f0b-8d52-b0cf01439c4f' : { //
        mensaje: `
        <p class="nota gb">Si las imágenes no fueron realizadas en UC CHRISTUS, adjuntar informe al final del proceso y llevar las imágenes + informe el día de la atención.</p>`,
        mensajeActivo: true
      },
      '326d7080-98e8-49be-adcb-a98a00f222db' : { //
        mensaje: `
        <p class="nota gb">Si las imágenes no fueron realizadas en UC CHRISTUS, adjuntar informe al final del proceso y llevar las imágenes + informe el día de la atención.</p>`,
        mensajeActivo: true
      }
    }
    
  }
}

export const ACTIVAR_MARCA_ORIGEN_DERIVACION = false;

